#main-funded-course {
  width: 1168px;
  margin: 0 auto;
  padding: 24px 16px 60px 16px;
  min-height: calc(100vh - 220px);
}

#main-funded-course > section > div:first-of-type {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#main-funded-course > section > div:first-of-type > div {
  width: 32px;
  height: 2px;
  background-color: #0558aa;
}

#main-funded-course > section > div:first-of-type > p {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

#main-funded-course > section > #funded-course-container {
  display: grid;
  grid-template-columns: 384px 384px 384px;
  gap: 8px;
}

/*---- ----*/
@media (max-width: 480px) {
  #main-funded-course {
    width: 100%;
    padding: 12px 8px 40px 8px;
    box-sizing: border-box;
  }

  #main-funded-course > section > div:first-of-type > p {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  #main-funded-course > section > #funded-course-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
  }
}
