#main-training-center {
  width: 1168px;
  margin: 0 auto;
  padding: 24px 16px 60px 16px;
  min-height: calc(100vh - 220px);
}

#main-training-center > section > div:first-of-type {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#main-training-center > section > div:first-of-type > div {
  width: 32px;
  height: 2px;
  background-color: #0558aa;
}

#main-training-center > section > div:first-of-type > p {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

#main-training-center > section > #container-training-center-ad-item {
  display: grid;
  grid-template-columns: 286px 286px 286px 286px;
  gap: 8px;
}

#main-training-center > section > #design-line {
  width: 100%;
  height: 2px;
  background-color: #d9d9d9;
  margin: 24px 0px;
}

#main-training-center > section > #container-training-center-item {
  display: grid;
  grid-template-columns: 286px 286px 286px 286px;
  gap: 8px;
}

#main-training-center > section > #container-training-center-item > a > div {
  width: 286px;
  height: 60px;
  border-radius: 4px;
  background-color: #218cf7;
  text-align: center;
  line-height: 60px;
  color: #fff;
}

@media (max-width: 480px) {
  #main-training-center {
    width: 100%;
    padding: 24px 8px 60px 8px;
    box-sizing: border-box;
  }

  #main-training-center > section > #container-training-center-ad-item {
    grid-template-columns: 1fr;
    flex-direction: column;
  }
  #main-training-center
    > section
    > #container-training-center-ad-item
    > a
    > div {
    height: 64px;
    border-radius: 4px;
    background: linear-gradient(
      to right,
      #218cf7 0%,
      #35a9e4 50%,
      #35a6e4 100%
    );
    padding: 8px 1px 1px 1px;
    box-sizing: border-box;
    overflow: hidden;
    grid-template-columns: 1fr;
    text-align: left;
  }

  #main-training-center
    > section
    > #container-training-center-ad-item
    > a
    > div
    > div {
    width: 100%;
    height: 55px;
    background-color: #fff;
    border-radius: 0px 0px 4px 4px;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 0px 0px 8px;
    box-sizing: border-box;
  }

  #main-training-center
    > section
    > #container-training-center-ad-item
    > a
    > div
    > div
    > img {
    height: 36px;
  }

  #main-training-center > section > #container-training-center-item {
    grid-template-columns: 1fr 1fr;
  }

  #main-training-center > section > #container-training-center-item > a > div {
    width: 100%;
    height: 48px;
    font-size: 14px;
    line-height: 48px;
  }

  #main-training-center > section > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  #main-training-center > section > div:first-of-type > div {
    width: 32px;
    height: 2px;
    background-color: #0558aa;
  }

  #main-training-center > section > div:first-of-type > p {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
  }
}
